.wrapper,
body {
  position: relative;
}
.close-all,
button {
  cursor: pointer;
  transition: 0.3s;
}
.close-all,
a,
a:hover,
button,
button:hover {
  transition: 0.3s;
}
a,
abbr,
acronym,
address,
applet,
big,
blockquote,
body,
caption,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}
.inside,
.wrapper,
body {
  margin: 0 auto;
}
:focus,
a:focus,
button:focus,
img:focus,
input:focus,
li:focus {
  outline: 0;
}
body {
  line-height: 1;
  max-width: 100%;
  font-family: Poppins, sans-serif;
  background: #f9f9f9;
  color: #292929;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
caption,
td,
th {
  text-align: left;
  font-weight: 400;
}
.inside,
.pop-inside {
  text-align: center;
  box-sizing: border-box;
  max-width: 100%;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
}
blockquote,
q {
  quotes: '' '';
}
button {
  background: 0 0;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a {
  display: inline-block;
  text-decoration: none;
}
.flex,
.pop-inside {
  display: -webkit-flex;
  display: -ms-flexbox;
}
input::-webkit-input-placeholder {
  opacity: 1;
}
input::-moz-placeholder {
  opacity: 1;
}
input:-moz-placeholder {
  opacity: 1;
}
input:-ms-input-placeholder {
  opacity: 1;
}
textarea::-webkit-input-placeholder {
  opacity: 1;
}
textarea::-moz-placeholder {
  opacity: 1;
}
textarea:-moz-placeholder {
  opacity: 1;
}
textarea:-ms-input-placeholder {
  opacity: 1;
}
input,
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
option,
select,
select:focus,
textarea {
  font-size: 16px;
  box-shadow: none;
}
:root {
  --main-color: #ae263f;
  --main-colorh: #8d1f33;
}
body.no-scroll {
  overflow-y: hidden;
}
.wrapper {
  width: 1920px;
  max-width: 100%;
  overflow: hidden;
}
.inside {
  width: 1920px;
  padding: 0;
}
.usn {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.not_allowed {
  cursor: not-allowed !important;
}

.flex {
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.flex-m {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-t {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.flex-b {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.flex-sb {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.flex-c {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.flex-sa {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.flex-e {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.flex-ww {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.pop,
.pop-inside {
  -webkit-justify-content: center;
}
.mob {
  display: none;
}
.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
section {
  box-sizing: border-box;
}
.close-all {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: -100;
  opacity: 0;
  visibility: hidden;
}
.close-all.active {
  z-index: 900;
  transition: 0.3s;
  opacity: 1;
  visibility: visible;
}
.pop-wrap {
  box-sizing: border-box;
  left: -100vw;
  top: 0;
  width: 100%;
  min-height: 100%;
  z-index: 10000;
  visibility: height;
  transition: 0.8s;
  background: 0 0;
  padding: 50px 0;
  overflow: visible;
  position: fixed;
}
.pop-wrap.active {
  visibility: visible;
  transition: 0.8s;
  top: 0;
  left: 0;
  overflow-y: auto;
}
.pop-inside {
  width: auto;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 10;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 15px;
}
.pop,
.pop-close {
  display: -webkit-flex;
  display: -ms-flexbox;
}
.pop-bg,
.pop-scroll {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}
.pop-bg {
  height: 100%;
  background: #2d2d31;
  opacity: 0.6;
  z-index: 2;
}
.pop,
.pop-in {
  padding: 0;
  position: relative;
  transition: 0.3s;
}
.pop {
  width: 100%;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-pagination__item,
.pop-close {
  -webkit-justify-content: center;
  cursor: pointer;
}
.pop-in {
  width: auto;
  display: inline-block;
  max-width: 100%;
  background: #fff;
  border: 2px solid #eff2f5;
  box-sizing: border-box;
  box-shadow: -4px -4px 40px rgba(239, 242, 245, 0.3),
    4px 4px 40px rgba(239, 242, 245, 0.3);
  border-radius: 20px;
}
.admin-nav,
.header {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}
.pop-close {
  right: 10px;
  top: 10px;
  position: absolute;
  width: 18px;
  height: 18px;
  z-index: 4;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  color: #b2b2b2;
  transition: 0.3s;
}
.input input,
.input label > p {
  font-size: 14px;
  box-sizing: border-box;
}
.modal-wrapper {
  position: fixed;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
}
.modal-wrapper__inner {
  margin: auto;
  position: relative;
  padding: 10px 0;
  width: 100%;
}
.input {
  position: relative;
  width: 100%;
  margin-bottom: 12px;
}
.input label {
  display: block;
  text-align: left;
}
.input label > p {
  font-weight: 600;
  cursor: pointer;
  z-index: 10;
  display: inline-block;
  position: relative;
  color: #4d4d4d;
  margin-bottom: 8px;
}
.input input {
  width: 100%;
  height: 54px;
  padding: 0 16px;
  transition: 0.3s;
  color: #383838;
  background: 0 0;
  border-radius: 5px;
  border: 1px solid #999;
}
.input input:focus {
  transition: 0.3s;
}
.input input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: 0.3s;
}
.input input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: 0.3s;
}
.input input:focus::placeholder {
  opacity: 0;
  transition: 0.3s;
}
.input input::-webkit-input-placeholder {
  color: #a6b0c3;
}
.input input:-ms-input-placeholder {
  color: #a6b0c3;
}
.input input::placeholder {
  color: #a6b0c3;
}
.input__inside {
  position: relative;
  z-index: 1;
}
.input__notification {
  font-weight: 500;
  font-size: 0;
  transition: 0.3s;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.input__notification p {
  display: inline-block;
  margin-left: 5px;
}
.input--error .input__notification {
  color: var(--main-color);
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: 0.3s;
}
.input--error input {
  border-color: #ffccd2;
}
.input__info {
  font-size: 14px;
  line-height: 13px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 31px;
}
.input__info span {
  color: var(--main-color);
}
.input__icon {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  right: 10px;
  top: 50%;
}
.input__icon p {
  font-size: 15px;
  line-height: 16px;
  color: #252727;
  margin-left: 10px;
}
.input__padding-small input {
  padding-left: 10px;
}
.show-password {
  position: absolute;
  right: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  width: 22px;
  height: 22px;
  background: url("data:image/svg+xml,%3Csvg width='22' height='19' viewBox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 4C13.76 4 16 6.24 16 9C16 9.65 15.87 10.26 15.64 10.83L18.56 13.75C20.07 12.49 21.26 10.86 21.99 9C20.26 4.61 15.99 1.5 10.99 1.5C9.59 1.5 8.25 1.75 7.01 2.2L9.17 4.36C9.74 4.13 10.35 4 11 4ZM1 1.27L3.28 3.55L3.74 4.01C2.08 5.3 0.78 7.02 0 9C1.73 13.39 6 16.5 11 16.5C12.55 16.5 14.03 16.2 15.38 15.66L15.8 16.08L18.73 19L20 17.73L2.27 0L1 1.27ZM6.53 6.8L8.08 8.35C8.03 8.56 8 8.78 8 9C8 10.66 9.34 12 11 12C11.22 12 11.44 11.97 11.65 11.92L13.2 13.47C12.53 13.8 11.79 14 11 14C8.24 14 6 11.76 6 9C6 8.21 6.2 7.47 6.53 6.8ZM10.84 6.02L13.99 9.17L14.01 9.01C14.01 7.35 12.67 6.01 11.01 6.01L10.84 6.02Z' fill='%23B3B3B3'/%3E%3C/svg%3E%0A")
    center no-repeat;
}
input[type='password'] ~ .show-password {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.257 6.962C20.731 7.582 20.731 8.419 20.257 9.038C18.764 10.987 15.182 15 11 15C6.818 15 3.236 10.987 1.743 9.038C1.51238 8.74113 1.38719 8.37592 1.38719 8C1.38719 7.62408 1.51238 7.25887 1.743 6.962C3.236 5.013 6.818 1 11 1C15.182 1 18.764 5.013 20.257 6.962V6.962Z' stroke='%23B3B3B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 11C12.6569 11 14 9.65685 14 8C14 6.34315 12.6569 5 11 5C9.34315 5 8 6.34315 8 8C8 9.65685 9.34315 11 11 11Z' stroke='%23B3B3B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.checkbox-wrap label {
  cursor: pointer;
}
.checkbox-wrap label p {
  font-size: 14px;
  color: #4d4d4d;
  line-height: 143%;
  margin-left: 13px;
}
input:checked ~ .checkbox {
  background-size: 10px 8px;
  transition: 0.3s;
  background-color: var(--main-color);
}
.checkbox {
  width: 16px;
  min-width: 16px;
  height: 16px;
  border: 1px solid var(--main-color);
  border-radius: 4px;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 1.6342L5.32597 6.53515L3.76797 8.16894L2.20996 6.53515L2.21861 6.52609L0 4.17446L1.55842 2.54067L3.77662 4.89271L8.44199 0L10 1.6342Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 0;
  transition: 0.3s;
}
.admin-section .inside {
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.admin-nav {
  width: 286px;
  min-height: 100vh;
  transition: 0.3s;
  z-index: 100;
  background: #fff;
  border-radius: 10px;
  padding: 29px 0;
  box-sizing: border-box;
}
.admin-nav__logo {
  padding: 0 5px;
}
.admin-nav__logo img {
  max-width: 100%;
}
.admin-nav.active {
  width: 65px;
}
.admin-nav.active nav ul li a,
.admin-nav.active nav ul li button {
  font-size: 0;
  transition: 0.3s;
}
.admin-nav.active nav ul li a::before,
.admin-nav.active nav ul li button::before {
  opacity: 0;
  transition: 0.3s;
}
.admin-nav.active nav ul li.active-child ul a,
.admin-nav.active nav ul li.active-child ul button {
  padding-left: 18px;
}
.admin-nav nav {
  margin-top: 75px;
}
.admin-nav nav ul li a,
.admin-nav nav ul li button {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  box-sizing: border-box;
  padding: 21px 22px;
  transition: 0.3s;
  position: relative;
  width: 100%;
  color: #292929;
}

.link_to_personality p {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
}

.link_to_personality .image-cont .img {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.link_to_personality .image-cont {
  display: flex;
}

.link_to_personality .image-cont .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.last-activity-table__item,
.user-management-table__item {
  display: -ms-grid;
  grid-gap: 30px;
  word-break: break-all;
  box-sizing: border-box;
  align-items: center;
}
.admin-nav nav ul li a.active,
.admin-nav nav ul li a.active svg,
.admin-nav nav ul li a:hover,
.admin-nav nav ul li a:hover svg,
.admin-nav nav ul li button.active,
.admin-nav nav ul li button.active svg,
.admin-nav nav ul li button:hover,
.admin-nav nav ul li button:hover svg {
  color: var(--main-color);
  transition: 0.3s;
}
.admin-nav nav ul li a svg,
.admin-nav nav ul li button svg {
  min-width: 20px;
  margin-right: 8px;
  font-size: 14px;
  transition: 0.3s;
}
.admin-nav nav ul li a svg path,
.admin-nav nav ul li button svg path {
  transition: 0.3s;
}
.admin-nav nav ul li a.active svg path,
.admin-nav nav ul li a:hover svg path,
.admin-nav nav ul li button.active svg path,
.admin-nav nav ul li button:hover svg path {
  fill: var(--main-color);
  transition: 0.3s;
}
.admin-nav nav ul li a.active::after,
.admin-nav nav ul li a:hover::after,
.admin-nav nav ul li button.active::after,
.admin-nav nav ul li button:hover::after {
  opacity: 1;
  transition: 0.3s;
}
.admin-nav nav ul li ul {
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.admin-nav nav ul li.active-child button svg path,
.admin-nav nav ul li.active-child > a svg path {
  fill: var(--main-color);
}
.admin-nav nav ul li.active-child ul {
  height: auto;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}
.admin-nav nav ul li.active-child ul a,
.admin-nav nav ul li.active-child ul button {
  padding-left: 50px;
}
.admin-content {
  width: calc(100% - 286px);
  background: #fafafa;
  transition: 0.3s;
  box-sizing: border-box;
}
.admin-content.active {
  width: calc(100% - 65px);
}
.header {
  height: 90px;
  background: #fff;
}
.header-left,
.header-right,
.toggler {
  height: 100%;
}
.header__button button {
  font-size: 14px;
  line-height: 21px;
  color: #999;
  padding-left: 35px;
  background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M13.4783 7.82608C13.7183 7.82608 13.9131 7.63129 13.9131 7.39129V2.1739C13.913 0.975652 12.9383 0 11.7391 0H2.1739C0.974796 0 0 0.975652 0 2.1739V17.8261C0 19.0243 0.974796 20 2.1739 20H11.7391C12.9383 20 13.913 19.0243 13.913 17.8261V12.6087C13.913 12.3687 13.7182 12.1739 13.4782 12.1739C13.2382 12.1739 13.0435 12.3687 13.0435 12.6087V17.8261C13.0435 18.5452 12.4583 19.1304 11.7391 19.1304H2.1739C1.45475 19.1304 0.869551 18.5452 0.869551 17.8261V2.1739C0.869551 1.45475 1.45475 0.869551 2.1739 0.869551H11.7391C12.4583 0.869551 13.0435 1.45475 13.0435 2.1739V7.39129C13.0435 7.63129 13.2383 7.82608 13.4783 7.82608Z' fill='%23999999'/%3E%3Cpath d='M19.5653 9.56525H5.65233C5.41233 9.56525 5.21753 9.76004 5.21753 10C5.21753 10.24 5.41233 10.4348 5.65233 10.4348H19.5653C19.8053 10.4348 20.0001 10.24 20.0001 10C20.0001 9.76 19.8053 9.56525 19.5653 9.56525Z' fill='%23999999'/%3E%3Cpath d='M19.8719 9.69391L17.2632 7.08521C17.0936 6.91565 16.818 6.91565 16.6484 7.08521C16.4788 7.25478 16.4788 7.53044 16.6484 7.70001L18.9493 10.0009L16.6484 12.3017C16.4788 12.4713 16.4788 12.747 16.6484 12.9165C16.7336 13.0009 16.8449 13.0435 16.9562 13.0435C17.0676 13.0435 17.1788 13.0009 17.2632 12.9174L19.8719 10.3087C20.0414 10.1391 20.0414 9.86347 19.8719 9.69391Z' fill='%23999999'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
    center left no-repeat;
  transition: 0.3s;
  cursor: pointer;
}
.header__button:hover button {
  background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M13.4783 7.82608C13.7183 7.82608 13.9131 7.63129 13.9131 7.39129V2.1739C13.913 0.975652 12.9383 0 11.7391 0H2.1739C0.974796 0 0 0.975652 0 2.1739V17.8261C0 19.0243 0.974796 20 2.1739 20H11.7391C12.9383 20 13.913 19.0243 13.913 17.8261V12.6087C13.913 12.3687 13.7182 12.1739 13.4782 12.1739C13.2382 12.1739 13.0435 12.3687 13.0435 12.6087V17.8261C13.0435 18.5452 12.4583 19.1304 11.7391 19.1304H2.1739C1.45475 19.1304 0.869551 18.5452 0.869551 17.8261V2.1739C0.869551 1.45475 1.45475 0.869551 2.1739 0.869551H11.7391C12.4583 0.869551 13.0435 1.45475 13.0435 2.1739V7.39129C13.0435 7.63129 13.2383 7.82608 13.4783 7.82608Z' fill='%23AE263F'/%3E%3Cpath d='M19.5653 9.56525H5.65233C5.41233 9.56525 5.21753 9.76004 5.21753 10C5.21753 10.24 5.41233 10.4348 5.65233 10.4348H19.5653C19.8053 10.4348 20.0001 10.24 20.0001 10C20.0001 9.76 19.8053 9.56525 19.5653 9.56525Z' fill='%23AE263F'/%3E%3Cpath d='M19.8719 9.69391L17.2632 7.08521C17.0936 6.91565 16.818 6.91565 16.6484 7.08521C16.4788 7.25478 16.4788 7.53044 16.6484 7.70001L18.9493 10.0009L16.6484 12.3017C16.4788 12.4713 16.4788 12.747 16.6484 12.9165C16.7336 13.0009 16.8449 13.0435 16.9562 13.0435C17.0676 13.0435 17.1788 13.0009 17.2632 12.9174L19.8719 10.3087C20.0414 10.1391 20.0414 9.86347 19.8719 9.69391Z' fill='%23AE263F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
    center left no-repeat;
  transition: 0.3s;
  color: var(--main-color);
}
.header-right {
  font-weight: 500;
  font-size: 14px;
  padding-right: 23px;
}
.header-right p {
  margin-right: 38px;
}
.toggler {
  width: 64px;
  box-sizing: border-box;
  cursor: pointer;
  background: var(--main-color);
  transition: 0.3s;
}
.toggler.active .toggler__arrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: 0.3s;
}
.toggler__arrow {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 8 14' fill='none'%3E%3Cpath d='M7 13L1 7L7 1' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  transition: 0.3s;
  width: 8px;
  height: 14px;
}
.toggler:hover {
  background: var(--main-colorh);
  transition: 0.3s;
}
.toggler:hover .toggler__arrow {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='14' viewBox='0 0 8 14' fill='none'%3E%3Cpath d='M7 13L1 7L7 1' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  transition: 0.3s;
}
.page-search {
  width: 420px;
  max-width: 100%;
  margin-left: 15px;
}
.page-search input {
  width: 100%;
  background: #f6f6f6;
  border-radius: 50px;
  font-size: 16px;
  color: #999;
  padding: 10px 25px;
  border: none;
  box-sizing: border-box;
}
.admin-content-inside {
  margin: 8px auto;
  width: calc(100% - 16px);
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
}

.feedback-table__item {
  -ms-grid-columns: 0.8fr 0.5fr 1.8fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr !important;
  grid-template-columns: 0.8fr 0.5fr 1.8fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr !important;
}

.user-management-table__item {
  display: grid;
  -ms-grid-columns: 0.7fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  grid-template-columns: 0.7fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  padding: 20px;
  border: 1px solid #f9f9f9;
  color: #383838;
}

.user-management-main .reject,
.user-management-main .accept,
.user-management-main .wait {
  font-size: 14px;
  font-weight: 500;
}
.user-management-main .reject {
  color: #e52937;
}
.user-management-main .accept {
  color: #27ae60;
}
.user-management-main .wait {
  color: #f2c94c;
}

.user-management-main {
  /* -ms-grid-columns: 0.7fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important; */
  grid-template-columns: 0.3fr 0.8fr 1fr 0.5fr 0.5fr 0.5fr 1fr 1fr !important;
}

.user-management-table__item > div:last-child {
  text-align: center;
}
.user-management-table__item:first-child {
  padding-top: 23px;
  padding-bottom: 23px;
  color: #383838;
  font-weight: 600;
  border: 1px solid #f6f6f6;
}
.user-management-table__item a {
  box-sizing: border-box;
  border-bottom: 1px solid #2f80ed;
  color: #2f80ed;
  transition: 0.3s;
}
.user-management-table__item a:hover {
  color: var(--main-color);
  border-color: var(--main-color);
  transition: 0.3s;
}
.user-management-table__personality {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.user-management-table__personality span {
  font-weight: 500;
  font-size: 13px;
  display: block;
}
.user-management-table__personality p {
  width: 100%;
  font-size: 12px;
  color: #999;
  margin-top: 8px;
}
.user-management-table__personality-icon {
  margin-right: 5px;
}
.table-button {
  margin: 0 12px;
}
.table-button a,
.table-button button {
  background: 0 0;
  border: none !important;
  color: #666;
  text-decoration: none;
  transition: 0.3s;
  white-space: nowrap;
  padding: 0;
}
.table-button a.active,
.table-button a:hover,
.table-button button.active,
.table-button button:hover {
  color: var(--main-color);
  transition: 0.3s;
}
.table-button:first-child {
  margin-left: 0;
}
.table-button:last-child {
  margin-right: 0;
}
.admin-pagination {
  padding: 30px 5px;
}
.admin-pagination__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  width: 32px;
  height: 32px;
  color: #424242;
  transition: 0.4s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  border-radius: 100%;
  margin: 0 3px;
}
.admin-pagination__item.active,
.admin-pagination__item:hover {
  background: #383838;
  transition: 0.4s;
  color: #fff;
}
.admin-pagination__item--arrow svg path {
  transition: 0.3s;
}
.admin-pagination__item--arrow:hover svg path {
  stroke: #fff;
}
.admin-pagination__item a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.last-activity-table__item {
  display: grid;
  -ms-grid-columns: 0.4fr 1.6fr;
  grid-template-columns: 0.4fr 1.6fr;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  padding: 20px;
  border: 1px solid #f9f9f9;
  color: #383838;
}
.last-activity-table__item:first-child {
  padding-top: 23px;
  padding-bottom: 23px;
  color: #383838;
  font-weight: 600;
  border: 1px solid #f6f6f6;
}
.back-page {
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid #f6f6f6;
  padding: 20px;
  box-sizing: border-box;
}
.back-page a {
  color: #383838;
}
.back-page svg {
  margin-right: 15px;
}
.last-activity-user-data {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.38fr 0.4fr 1fr;
  grid-template-columns: 0.38fr 0.4fr 1fr;
  border-bottom: 1px dashed #e8e8e8;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
}
.last-activity-user-data__item p {
  font-weight: 600;
  font-size: 14px;
  color: #383838;
  margin-bottom: 10px;
}
.last-activity-user-data__item span {
  font-size: 13px;
  color: #383838;
  display: block;
}
.pop-confirmation-action {
  width: 620px;
  max-width: 100%;
  padding: 35px 15px;
  box-sizing: border-box;
}
.pop-confirmation-action .button a,
.pop-confirmation-action .button button {
  width: 224px;
}

.button-wrap .button {
  margin: 0 15px;
}
.pop-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #222531;
  text-align: center;
  margin-bottom: 25px;
}
.pop-user-avatar {
  width: 116px;
  height: 116px;
  margin: 25px auto;
}
.pop-user-avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 100%;
}

.verification_user_action .button {
  margin: 5px 0;
}
.verification_user_action .button button {
  padding: 8px;
  font-size: 12px;
}

.button {
  margin: 25px 0;
}
.button a,
.button button,
.button p {
  padding: 18px 16px;
  border: none;
  background: var(--main-color);
  box-sizing: border-box;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border: 1px solid var(--main-color);
  cursor: pointer;
}
.button a svg,
.button button svg,
.button p svg {
  margin-right: 10px;
  vertical-align: bottom;
}
.button a:hover,
.button button:hover,
.button p:hover {
  background: var(--main-colorh);
  border-color: var(--main-colorh);
}
.button--type2 a,
.button--type2 button,
.button--type2 p {
  border: 1px solid #b5bcc2;
  color: #c4c4c4;
  background: 0 0;
}
.button--type3 a,
.button--type3 button,
.button--type3 p {
  color: var(--main-color);
  background: 0 0;
}
.button--type3 a:hover,
.button--type3 button:hover,
.button--type3 p:hover {
  background: var(--main-colorh);
  color: #fff;
}
.authorization-section {
  min-height: 100vh;
  padding: 0;
}
.authorization-section .inside {
  width: 100%;
  padding: 0;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.authorization-right {
  min-height: 100vh;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.authorization__form {
  width: 416px;
  max-width: 100%;
  text-align: left;
}
.authorization__form .button {
  width: 230px;
  max-width: 100%;
}
.authorization__form .button a,
.authorization__form .button button {
  width: 100%;
}
.authorization__form .input {
  margin: 25px 0;
}
.authorization__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #4d4d4d;
  margin-bottom: 40px;
}
.authorization__text {
  font-size: 14px;
  line-height: 214%;
  color: #666;
  margin-bottom: 21px;
}
.authorization__google {
  width: calc(100% - 63px);
}
.authorization__google a,
.authorization__google button {
  padding: 8px 16px;
  width: 100%;
  height: 40px;
  background: #2f80ed;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  box-sizing: border-box;
  position: relative;
}
.authorization__google a svg,
.authorization__google button svg {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.authorization__fb a,
.authorization__fb button {
  background: #f2f2f2;
  border-radius: 5px;
  padding: 9.5px 15px;
  box-sizing: border-box;
}
.authorization__line {
  margin-top: 15px;
  margin-bottom: 25px;
}
.authorization__link {
  font-size: 14px;
  line-height: 20px;
}
.authorization__link a {
  color: var(--main-color);
}
.authorization__link a:hover {
  color: var(--main-colorh);
}
.authorization__header {
  font-size: 16px;
  color: #4d4d4d;
  position: absolute;
  right: 50px;
  top: 38px;
}
.authorization__header a {
  color: var(--main-color);
  font-weight: 600;
}
.authorization__header a:hover {
  color: var(--main-colorh);
}
.authorization__back {
  margin-bottom: 25%;
}
.authorization__back p {
  margin-left: 25px;
}
.authorization__back a {
  font-size: 16px;
  color: #4d4d4d;
}
.authorization__back a:hover {
  color: var(--main-color);
}
.authorization__input-code {
  font-size: 13px;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.authorization__input-code span {
  font-weight: 700;
  color: var(--main-color);
}
.authorization__new-password-info {
  margin-top: 90px;
  padding-top: 70px;
  box-sizing: border-box;
  border-top: 1px solid #d8d8d8;
  text-align: left;
}
.authorization__new-password-info ul li {
  font-weight: 500;
  font-size: 12px;
  line-height: 200%;
  color: #666;
}
.authorization__new-password-info ul li span {
  color: #ae263f;
}
.authorization__new-password-info ul li + li {
  margin-top: 16px;
}
.feedback-details-main-content .administrator-section {
  margin-top: 90px;
}

.feedback-details-main-content {
  text-align: left;
  padding-left: 20px;
  padding-bottom: 30px;
}

.feedback-single-Detail {
  display: flex;
  margin-bottom: 30px;
}
.feedback-single-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feedback-single-Detail.feedBackTyp {
  margin-top: 40px;
}
.feedback-single-Detail.col {
  flex-direction: column;
}
.feedback-single-Detail.col label {
  margin-bottom: 10px;
}

.feedback-single-Detail input {
  height: 39px;

  width: 600px;
  font-weight: 400;
  font-size: 14px;
  padding: 0 10px;
  border-radius: 5px;
  outline: none;
}
.feedback-single-Detail textarea {
  height: 144px;
  width: 600px;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 10px;
  border-radius: 5px;
  outline: none;
}

.feedback-single-Detail label {
  width: 150px;
  font-weight: 600;
  color: #4d4d4d;
  font-size: 14px;
}

.feedback-single-Detail a {
  text-decoration: underline;
  color: #2f80ed;
}

.feedback-single-Detail .attachment span {
  display: block;
  margin-bottom: 15px;
}

.feedback-single-Detail .attachment span a:first-child {
  margin-right: 15px;
}

.feedback-details-main-title {
  color: #4d4d4d;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 25px;
}

.administrator-section {
  display: flex;
  flex-direction: row;
}
.administrator-section-right {
  padding-left: 30px;
  margin-top: 80px;
}

.administrator-section-right div {
  margin-bottom: 30px;
  display: flex;
}
.administrator-section-right div label {
  width: 150px;

  font-weight: 600;
  color: #4d4d4d;
  font-size: 14px;
}

.administrator-section-left .buttons {
  display: flex;
  justify-content: end;
}
.administrator-section-left .buttons button {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  width: 90px;
  height: 40px;
  border-radius: 5px;
}
.administrator-section-left .buttons button a {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #b3b3b3;
}

.administrator-section-left .buttons button:first-child {
  background-color: #f2f2f2;
  color: #b3b3b3;
  margin-right: 18px;
}

.administrator-section-left .buttons button:nth-child(2) {
  background-color: #ae263f;
  color: #ffffff;
}

.administrator-section .statusSelectable {
  height: 39px;
  width: 620px;
  font-weight: 400;
  font-size: 14px;
  padding: 0 10px;
  border-radius: 5px;
  outline: none;
}
@media screen and (max-width: 1440px) {
  .admin-nav {
    position: fixed;
    left: -286px;
    top: 0;
    transition: 0.5s;
  }
  .admin-nav .header__logo {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 32px 0;
  }
  .admin-nav.active {
    width: 286px;
    left: 0;
    transition: 0.5s;
  }
  .admin-nav.active nav ul li a {
    font-size: 12px;
  }
  .admin-nav.active nav ul li a::before {
    opacity: 1;
  }
  .admin-nav.active nav ul li button {
    font-size: 12px;
    font-weight: 500;
  }
  .admin-nav.active nav ul li.active-child ul a,
  .admin-nav.active nav ul li.active-child ul button {
    padding-left: 50px;
  }
  .admin-content,
  .admin-content.active {
    width: 100%;
  }
  .toggler {
    position: relative;
    transition: 0.5s;
    left: 0;
  }
  .toggler__arrow {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: 0.5s;
  }
  .toggler.active {
    left: 288px;
    transition: 0.5s;
  }
  .toggler.active .toggler__arrow {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    transition: 0.5s;
  }
}
@media screen and (max-width: 960px) {
  .page-search {
    width: 280px;
  }
}
@media screen and (max-width: 920px) {
  .user-management-table__item {
    padding: 0;
    display: block;
    font-size: 14px;
  }
  .user-management-table__item > div {
    padding: 17px 15px;
    box-sizing: border-box;
    border: 1px solid #f9f9f9;
  }
  .user-management-table__item > div:last-child {
    text-align: left;
  }
  .user-management-table__item > div.flex {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .user-management-table__item > div::before {
    content: attr(data-label);
    width: 100%;
    display: block;
    font-size: 15px;
    box-sizing: border-box;
    color: #383838;
    background: 0 0;
    margin-bottom: 10px;
  }
  .user-management-table__item:first-child {
    display: none;
  }
  .user-management-table__item::before {
    content: attr(data-label-item);
    width: 100%;
    color: #fff;
    display: block;
    padding: 15px;
    background: #383838;
    font-size: 16px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 820px) {
  .page-search {
    width: calc(100% - 110px);
  }
  .header {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 180px;
  }
  .header-left,
  .header-right {
    width: 100%;
    height: 90px;
    margin: 0;
  }
  .header-right {
    padding-left: 15px;
    padding-right: 15px;
  }
  .last-activity-table__item {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    padding: 15px;
  }
  .last-activity-table__item:first-child {
    padding: 15px;
  }
  .last-activity-user-data {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
@media screen and (max-width: 520px) {
  .button-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .button-wrap .button {
    width: 100%;
    text-align: center;
    margin: 7px 0;
  }
}
