@import './main.min.css';
@import './css/react-notifications.css';
@import './css/swiper.min.css';

.avoid-clicks-disable {
  pointer-events: none;
}

.avoid-clicks-enable {
  pointer-events: auto;
}

.cursor-active {
  cursor: pointer;
}

.hide {
  display: none;
}

.event > div:last-child {
  text-align: left !important;
}
.user-management-table__item > div:last-child {
  text-align: center;
}
.user-management-table__item {
  grid-template-columns: 0.7fr 1fr 1fr 1fr 1fr 1fr 0.5fr 1fr;
}
